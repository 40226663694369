





















































































































































































































































































































































































































































































































/**
 * Edit project form
 * We are using this form to edit the main project too
 * and the  metadata in the singleproject section
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import FormatDate from "@/utils/FormatDate";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Project from "@/model/Project";
import DateToIsoString from "@/utils/DateToIsoString";
import ReportGeneratorModal from "@/components/project/SingleProject/Report/ReportGeneratorModal.vue";
import ModelUser from "@/model/ModelUser";
import UserAccess from "@/model/User/UserAccess";
import { VolumeAnalysisVariant } from "@/interface/projectsInterface";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { BatteryDevice, DeviceState } from "@/model/SingleDeviceModal";
import { DeviceEndPoint } from "@/services/InventoryService";

@Component({
  name: "UpdateProjectForm",
  components: {
    Multiselect,
    ReportGeneratorModal
  },
  computed: {
    ...mapGetters({
      getAllDevices: "getAllDevices",
      getAllBatteryDevicesInProject: "getAllBatteryDevicesInProject",
      getAllBatteryDevices: "getAllBatteryDevices",
      getDeviceById: "getDeviceById"
    }),
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      allProjectStatus: "GetAllStatus",
      project: "GetCurrentProject",
      countrysList: "GetAllAvailableCountrys",
      allUserOptions: "GetAllUserOption"
    })
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd", "updateDevice"]),
    ...mapActions("projects", {
      updateCurrentProject: "UpdateCurrentProject",
      DetailsOfProject: "DetailsOfProject",
      GetAllProjectsFromBackEnd: "GetAllProjectsFromBackEnd",
      GetAllProjectStatus: "GetAllStatusFromBackEnd",
      GetAllCountrys: "GetCountryList",
      GetAllUser: "GetAllUsersFromTheProject"
    })
  }
})
export default class UpdateProjectForm extends Vue {
  /*----------  Vuex  ----------*/
  readonly getUserAccess!: UserAccess;
  private readonly allProjectStatus!: Array<object>;
  readonly project!: Project;
  readonly countrysList!: Array<object>;
  readonly allUserOptions!: Array<ModelUser>;

  readonly getDeviceById!: any;
  updateDevice!: Function;
  private getAllDevicesFromBackEnd!: Function;
  getAllBatteryDevicesInProject!: Function;
  readonly getAllBatteryDevices!: Array<BatteryDevice>;

  BatteryDevicesInThisProject!: Array<BatteryDevice>;
  private updateCurrentProject!: Function;
  private DetailsOfProject!: Function;
  private GetAllProjectsFromBackEnd!: Function;
  private GetAllProjectStatus!: Function;
  private GetAllCountrys!: Function;
  private GetAllUser!: Function;
  /*----------  end vuex  ----------*/

  @Prop({ type: Boolean, default: false }) isMainProject!: boolean;
  @Prop({ type: [Number, String], default: null }) mainProjectId!: number | string;
  @Prop({ type: Boolean, default: false }) isMetaDataMainProject!: boolean;

  /*=============================================
  =            Local data            =
  =============================================*/
  updateStatus: null | ActionMessage = null;
  startDateTime: string = "";
  endDateTime: string = "";
  selected: string = "";
  show: boolean = true;
  startDate: null | string = null;
  endDate: null | string = null;
  loading = false;
  allVolumeAnalysisVariantOptions: Array<VolumeAnalysisVariant> = [];
  /*=====  End of Local data  ======*/

  /*=============================================
  =            vuejs life cycles            =
  =============================================*/

  mounted() {
    this.updateStatus = null;
    if (this.isMainProject) {
      this.DetailsOfProject(this.mainProjectId).then(() => {
        this.setTimeValue().then(() => this.formatDate());
        this.show = !this.isMainProject || this.isMetaDataMainProject;
      });
    } else {
      this.DetailsOfProject(this.$route.params.id).then(() => {
        this.GetAllProjectStatus();
        this.GetAllCountrys();

        this.setTimeValue().then(() => this.formatDate());
      });
    }
  }

  async created() {
    if (!this.isMainProject) {
      await this.getAllDevicesFromBackEnd();
      await this.getAllBatteryDevicesInProject(this.$route.params.id);
    }
  }
  /*----------  Computed  ----------*/
  get statusOption() {
    return this.project.isFinish
      ? this.allProjectStatus
      : this.allProjectStatus.filter((status: any) => status.id !== 4);
  }

  get volumeAnalysisVariantsOption() {
    this.allVolumeAnalysisVariantOptions = [
      {
        name: "Volume60Minutes",
        label: "59+1"
      },
      {
        name: "Volume15Minutes",
        label: "15x4"
      },
      {
        name: "Volume1Minutes",
        label: "1x60"
      }
    ];
    return this.allVolumeAnalysisVariantOptions;
  }

  get isTheFormValid() {
    if (this.isMainProject) {
      return !this.project.status || !this.project.country || !this.startDate || !this.endDate;
    } else {
      return (
        !this.project.status ||
        !this.project.country ||
        !this.project.federalState ||
        !this.startDate ||
        !this.endDate ||
        !this.startDateTime ||
        !this.endDateTime
      );
    }
  }

  /*----------  Methods  ----------*/
  async updateProject() {
    this.updateStatus = null;
    if (!this.project.status || !this.project.type) {
      this.updateStatus = {
        class: "Danger",
        msg: "Please check that all required data  is present."
      };
      return false;
    }
    this.loading = true;
    const startDate = DateToIsoString(new Date(`${this.startDate} ${this.startDateTime}`));
    const endDate = DateToIsoString(new Date(`${this.endDate} ${this.endDateTime}`));

    let updatedProjectInformation = this.project.formatToUpdate();
    updatedProjectInformation.dateEnd = endDate;
    updatedProjectInformation.dateStart = startDate;

    // Update the project
    let updateProjectStatus = await this.updateCurrentProject(updatedProjectInformation);

    if (updateProjectStatus.status === 200) {
      this.BatteryDevicesInThisProject = this.getAllBatteryDevicesInProject(this.project.id);

      this.BatteryDevicesInThisProject.forEach(function (this: any, batteryDevice) {
        const device = this.getDeviceById(batteryDevice.id);
        if (this.project.isFinish && device.projects.length <= 1) device.state = DeviceState.AVAILABLE;
        else if (this.project.isProjectActive) device.state = DeviceState.IN_FIELD;
        else if ((this.project.isProjectPlaning || this.project.isProjectDismantling) && device.projects.length <= 1)
          device.state = DeviceState.BOOKED;
        this.updateDevice(device);
      }, this);

      this.updateStatus = {
        class: "success",
        msg: this.$t("projects.statusMsg.updateSuccess")
      };
    } else if (updateProjectStatus.response.status === 400) {
      this.updateStatus = {
        class: "danger",
        msg: updateProjectStatus.response.data.message
      };
    } else if (updateProjectStatus.response.status === 403) {
      this.updateStatus = {
        class: "danger",
        msg:
          (this as any).$t(updateProjectStatus.response.data.label) +
          " [" +
          updateProjectStatus.response.data.message.split("[").pop()
      };
    } else {
      this.updateStatus = {
        class: "danger",
        msg: (this as any).$t("archiveProjects.actions.action400res")
      };
    }

    let mainProjectDetails: any = false;

    if (this.isMainProject) {
      mainProjectDetails = await this.DetailsOfProject(this.mainProjectId);

      if (mainProjectDetails.status === 200 && updateProjectStatus.status === 200) {
        this.$bvModal.hide("editMainProject");
        this.updateStatus = {
          class: "success",
          msg: this.$t("projects.statusMsg.updateSuccess")
        };
        this.$emit("updateMainStatus", this.updateStatus);
        this.GetAllProjectsFromBackEnd();
      } else {
        this.updateStatus = {
          class: "danger",
          msg: this.$t("project.error.delete.failed-by-status")
        };
      }

      if (this.isMetaDataMainProject) this.show = !this.show;
    } else {
      this.DetailsOfProject(this.$route.params.id);
      this.show = !this.show;
    }

    // Workaround to Update inventory.project
    if (mainProjectDetails && mainProjectDetails.status === 200) {
      await this.updateInventoryProject(mainProjectDetails);
    } else if (updateProjectStatus.status === 200) {
      await this.updateInventoryProject(updatedProjectInformation);
    }

    this.loading = false;
  }

  handleUpdateProjectCancel() {
    this.loading = true;
    if (this.isMainProject) {
      this.DetailsOfProject(this.mainProjectId).then(() => {
        this.loading = false;
      });
      this.$bvModal.hide("editMainProject");
      if (this.isMetaDataMainProject) this.show = !this.show;
    } else {
      this.DetailsOfProject(this.$route.params.id).then(() => {
        this.setTimeValue().then(() => this.formatDate());
        this.loading = false;
      });
      this.show = !this.show;
    }
  }

  formaTime(time: string) {
    return time.split("T")[1].slice(0, 8);
  }

  async updateInventoryProject(project: any) {
    // Update the Inventory Project
    let updateInventoryProject = await DeviceEndPoint.updateProjectState({
      id: project.id,
      name: project.name,
      statusId: project.projectStatusId
    });

    if (
      updateInventoryProject.status === 400 ||
      updateInventoryProject.status === 404 ||
      updateInventoryProject.status === 403
    ) {
      this.updateStatus = {
        class: "danger",
        msg: this.$t("projects.statusMsg.updateErrorInventory")
      };
    }
  }

  async setTimeValue() {
    this.endDateTime = this.formaTime(this.project.dateEnd);
    this.startDateTime = this.formaTime(this.project.dateStart);
  }

  async formatDate() {
    this.startDate = FormatDate(this.project.dateStart).split("/").reverse().join("-");
    this.endDate = FormatDate(this.project.dateEnd).split("/").reverse().join("-");
  }

  async openEditMetadataForm() {
    this.DetailsOfProject(this.$route.params.id);
    await this.GetAllUser();
    this.show = !this.show;
  }

  handleCloseOfTheAlert() {
    this.updateStatus = null;
  }

  get getReportPermission() {
    return this.getUserAccess.Access.VIEW.project.report.generateProjectReport || this.project.switchingReport;
  }
}
